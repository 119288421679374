<template>
  <div>
    <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks" v-if="!completed"></v-tour>
  </div>
</template>

<script>
export default {
  name: 'my-tour',
  data () {
    return {
      completed: false,
      currentStep: 0,
      steps: [
        {
          target: '.trading-vue-ohlcv',
          content: `<b>O</b>: Open Price; <b>H</b>: Highest Price; <b>L</b>: Lowest Price; <b>C</b>: Closing Price; <b>V</b>: Volume`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.trading-vue-grid-ux-0',
          content: '<b>CANDLESTICK CHART</b> indicates the open, high, low and close of the day <br><br> <b>VOLUME CHART</b> indicates the total volume traded for the day',
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.trading-vue-grid-1',
          content: '<b>RSI</b> shows the price momentum of the market. Numbers above 70 indicates overbought and number below 30 indicates oversold',
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.trading-vue-grid-2',
          content: "MACD is an indicator for trend-following between two moving averages of the market. When  <span style='color:#619acd'>BLUE</span> line crosses up the  <span style='color:#fcd855'>YELLOW</span> line indicates BUY signal or otherwise",
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#game-step-2',
          content: `<b>TIMER</b> shows the time countdown before moving to the next candlestick <br><br>
                    <b>DAY</b> shows the number of trading days that the game has moved <br><br>
                    <b>PRICE</b> is the closing price of the trading day and also the excuted price that you will buy or sell`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#orderDetailsAnchor',
          content: `QUANTITY is the number of contracts that you want to buy or sell. <br><br>
                    <span style='color:green'>BUY</span> or  <span style='color:red'>SELL</span> button is for you to key in your buy/sell order`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#game-step-4',
          content: `<b>NET POSITION</b> shows your current open position i.e <span style="color: green">positive(+)</span> number indicates <span style="color: green">Long (Buy)</span> positions and <span style="color: red">negative (-)</span> indicates <span style="color: red">Short (Sell)</span> positions  <br><br><b>CURRENCY</b> shows the denominated currency of the product. <br><br><b>INITIAL CASH BALANCE</b> shows your initial virtual capital when kickstarting the game. <br><br><b>TOTAL PROFIT/LOSS</b> shows your accumulated profit and loss of the game`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'left-start'
          }
        },
        {
          target: '#game-step-5',
          content: ` <b>NET EQUITY</b> shows the <b>INITIAL CASH BALANCE</b> add/subtract the <b>TOTAL PROFIT/LOSS</b><br><br><b>MARGIN</b> shows the utilised capital in maintaining open position(s). You may refer to Margin required per contract in INFO to establish each and subsequent new position(s). <br><br><b>MARGIN EXCESS/CALL</b> shows the remaining purchasing power to buy/sell a futures contract. <ul> <li><span style="color:green">Positive (+)</span> number indicates <span style="color:green">Margin Excess</span> that you have sufficient funds to maintain your open position(s) and/or could enter more positions if the dunds are above tehe Margin required per contract.</li><li><span style="color:red">Negative (-)</span> number indicates <span style="color:red">Margin Call</span> that you have insufficient funds to maintain your open position(s) and required to reduce your open position(s) until no Margin Call level.</li></ul> <br><br><b>EQUITY/MARGIN (%)</b> shows the percenrtage of the remaining <b>CASH BALANCE</b> vs required <b>MARGIN</b>. If the percentage approaches 100% indicates that you are at the high level of leveraging. If the percentrage falls below 100% indicates that you are in Margin Call.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'left-start'
          }
        },
        {
          target: '#game-step-6',
          content: `<b>ORDER JOURNAL</b> shows the confirmed trades details that you key in. <br><br><span style="color:red">RED row(s)</span> indicates force-liquidation trades by the system`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'top'
          },
          before: async type => new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(true)
            }, 500);
          })
        },
        {
          target: '.margin-call-container',
          content: `<b>MARGIN CALL</b> window will pop-up when the number turns <span style="color:red">negative (-)</span> <br><br>
                    You are required to close some position(s) to the level with <b>NO MARGIN CALL</b> (positive number) or else the system will force-liquidate your position(s) highlihted in <span style="red">RED</span> in the Order Journal`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'top'
          },
          before: async type => new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(true)
            }, 500);
          })
        },
        {
          target: '.insuf-funds-container',
          content: `<b>INSUFFICIENT FUNDS</b> window will pop-up when you do not have enough capital to key in the desired order quantity. You need to check the Margin required per contract in the <b>INFO</b>`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'top'
          },
          before: async type => new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(true)
            }, 500);
          })
        },
        {
          target: '.game-ended-container',
          content: `You have completed the game when the number of trading days reaches 60. <br><br>Check if your name is in our top ranking of the <b>LEADERBOARD</b>Click Player to get your <b>TRADING SUMMARY REPORT</b>`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'top'
          },
          before: async type => new Promise((resolve, reject) => {
            this.$emit('showGameEnded',true)
            setTimeout(() => {
              resolve(true)
            }, 500);
          })
        },
       
      ],
      myCallbacks: {
        onNextStep: this.onNextStep,
        onFinish: this.onFinish,
        onSkip: this.onSkip,
      }
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.$tours['myTour'].start();
    }, 1000);
  },
  created(){
    const tutorial = localStorage.getItem('gameTutorial');

    if(tutorial){
      this.completed = true;
    }
  },
  methods:{
    onNextStep(step){
      this.currentStep = step;
      if(step == 8){
        this.$emit('showPopUp','openMarginCall')
      }

      if(step == 9){
        this.$emit('showPopUp','closeMarginCall')
      }

      if(step == 10){
        this.$emit('showPopup','closeInsufficientFunds')
      }
    },
    onFinish(){
      this.$emit('startGame',true)
    },
    onSkip(){
      this.$emit('startGame',true)
    }
  }
}
</script>
