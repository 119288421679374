<template>
  <div class="wrapper">
    <GameTour @showPopUp="handleGamePopOut" @startGame="startGameHandler" @showGameEnded="showEndedHandler"/>
    <div class="game-grid" ref="gameContainer">
      <!-- Mobile Tabs -->
      <div class="first-row-mobile" v-show="isMobile">
        <div class="tabs">
          <a :class="[{active: currentTab == 0},'order-tab tab-item']" @click="currentTab = 0" href="#orderDetailsAnchor">Enter Order</a>
          <a :class="[{active: currentTab == 1},'order-tab tab-item']" @click="currentTab = 1" href="#accountInfoAnchor">Account Info</a>
          <a :class="[{active: currentTab == 2},'order-tab tab-item']" @click="currentTab = 2" href="#orderJournalAnchor">Order Journal</a>
        </div>
        <div class="day-info">
          <div class="progress" id="countdown">
            <Timer
              :timeLimit="gameTimer"
              ref="mobileTimer"
            />
          </div>
          <div class="current-day-information">
            <div class="info">
              <p>DAY</p><span>{{this.gameSession.current_duration}}</span>
            </div>
            <div class="info">
              <p>PRICE</p><span>{{this.currentDayPrice}}</span>
            </div>
          </div>
        </div>
      </div> 
      <div class="left-col">
        <!-- Charts -->
        <div class="charts">
          <trading-vue :data="chartsDc"
            titleTxt=""
            colorTitle="black"
            ref="chart"
            :color-back="colors.colorBack"
            :color-grid="colors.colorGrid"
            :color-text="colors.colorText"
            :width="chartWidth"
            :height="chartHeight"
            :indexBased="true"
            :overlays="overlays"
            v-if="chartsDc!=null"
            id="game-step-1"
            >
          </trading-vue>
        </div>

        <!-- Order Journal -->
        <div class="order-journal spacerTop20" v-if="!isMobile" id="game-step-6">
          <h3 >Order Journal</h3>
          <TableColumn :propData="orderJournalList" :header="orderJournalHeader" class="spacerTop10" columnTemplateCount="repeat(4,1fr)" dataType="journal" gridSize="4"/>
        </div>
      </div>

      <!-- Game Details -->
      <div class="game-details" ref="gameDetailsContainer">
        <!-- First Row -->
        <div class="first-row" v-show="!isMobile" id="game-step-2">
          <div class="progress" id="countdown">
            <Timer
              :timeLimit="gameTimer"
              ref="timer"
            />
          </div>
          <div class="current-day-information">
            <div class="info">
              <p>DAY</p><span>{{this.gameSession.current_duration}}</span>
            </div>
            <div class="info">
              <p>PRICE</p><span>{{this.currentDayPrice}}</span>
            </div>
          </div>
        </div> 

        <!-- Order Details -->
        <div class="order-details spacerTop20" id="orderDetailsAnchor">
          <h3 class="row-title" style="margin-bottom:20px">Enter Order</h3>
          <TableRow 
            :isInput="true"
            :isNumeric="true"
            :tableDetails="[{title: 'Quantity'}]" 
            @valueChange="updateQuantity"
          />
          <div class="actions-tab spacerTop20">
            <button class="buy-action action" @click="placeOrderCall('BUY')" :disabled="isAPICalling || gameEnded">BUY</button>
            <button class="sell-action action" @click="placeOrderCall('SELL')" :disabled="isAPICalling || gameEnded">SELL</button>
          </div>
        </div>

        <!-- Account Info -->
        <div class="account-info spacerTop20" id="accountInfoAnchor">
          <h3 class="row-title">Account Info</h3>
          <div id="game-step-4">
            <TableRow :tableDetails="[{title: 'Net Position',value: this.gameSession.net_position}]" class="spacerTop10" />

            <TableRow :tableDetails="[{title: 'Currency',value: currency}]" class="spacerTop20"/>
            <TableRow :tableDetails="[{title: 'Initial Cash Balance',value: this.gameSession.initial_cash}]" :isNumeric="true" :isMoneyFormat="true"/>
            <TableRow :tableDetails="[{title: 'Total Profit/Loss',value: this.gameSession.profit}]" :isNumeric="true" :isColorCoded="true" :isMoneyFormat="true"/>
          </div>

          <div id="game-step-5">
            <TableRow :tableDetails="[{title: 'Net Equity',value: this.gameSession.net_equity}]" class="spacerTop20" :isNumeric="true" :isColorCoded="true" :isMoneyFormat="true"/>
            <TableRow :tableDetails="[{title: '(-) Margin',value: this.gameSession.margin}]" :isNumeric="true" :isColorCoded="true" :isMoneyFormat="true" :isAlwaysRed="true"/>
            <TableRow :tableDetails="[{title: 'Margin Excess/Call',value: this.gameSession.margin_excess}]" :isNumeric="true" :isColorCoded="true" :isMoneyFormat="true"/>

            <TableRow :tableDetails="[{title: 'Equity / Margin (%)',value: this.gameSession.equity_margin_ratio}]" class="spacerTop20" :isNumeric="true" :isColorCoded="true" :isPercentage="true"/>
          </div>
        </div>
      </div>

      <!-- Order Journal Mobile -->
      <div class="order-journal-mobile spacerTop20" v-if="isMobile" id="orderJournalAnchor"> 
        <h3 >Order Journal</h3>
        <TableColumn :propData="orderJournalList" :header="orderJournalHeader" class="spacerTop10" columnTemplateCount="repeat(4,1fr)" dataType="journal" gridSize="4"/>
      </div>
    </div>

     <p class="note mobile">You may end the game at any time by clicking the logo on the top left.</p>
  </div>
</template>

<script>
//Charts
import {TradingVue, DataCube } from 'trading-vue-js'
import Overlays from 'tvjs-overlays'

//Pusher Js
import Pusher from 'pusher-js';

//Components
import TableRow from '@/components/TableRow';
import TableColumn from '@/components/TableColumn';
import GameTour from '@/components/GameTour';
import Timer from '../components/Timer';

//API
import { gameSessionStore, gameSessionStart, marketDataPoints, placeOrder, gameSessionEnd, gameEnd,gameSessionShow } from '@/services/api/game.js'; 

import Swal from 'sweetalert2'
import moment from 'moment';
import {eventBus} from '@/main.js'


export default {
  components:{
    TableRow,
    TableColumn,
    Timer,
    TradingVue,
    GameTour
  },
  beforeRouteLeave(to, from, next){
    const vm = this;
    if(!this.gameEnded){
      this.$swal({
        title: 'Are you sure to leave the game?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        }
      })
      return;
    }
    next();
  },
  data(){
    return{
      overlays: [Overlays['MACD'],Overlays['RSI']],
      chartsDc: null,
      chartWidth: null,
      chartHeight: null,
      pusherInstance: null,
      pusherChannel: null,
      gameSession: '',
      currentDayPrice: '',
      isMarginCall: false,
      dummyMarginCall: false,
      dummyInsufficientFunds: false,
      dummyGameEnded: false,
      gameEnded: false,
      marketDataPoints: [],
      orderQuantity: 0,
      orderJournalList: [],
      gameTimer: 0,
      isAPICalling: false,
      isForcedQuit: false,
      orderJournalHeader:['Day','Buy/Sell','Quantity','Price'],
      currentTab : 0,
      isMobile: false,
      colors: {
        colorBack: '#fff',
        colorGrid: '#eee',
        colorText: '#333',
      },
      currency: '',
    }
  },
  async created(){
    window.addEventListener('beforeunload', this.handlePageLeave)

    //Start loader
    eventBus.$emit('loader',true);

    //Assign Data
    this.currentDayPrice = this.computedCurrentDayPrice;

    try {

      this.$store.commit('setCurrentGame',null);
      
      //Initialize Pusher
      this.pusherInstance = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
        cluster: 'ap1'
      });

      //Start game session
      const gameSessionAPI = await gameSessionStore('',this.$route.params.gameId);
      this.gameSession = gameSessionAPI.data.response.gameSession;

      const gameSessionsShowAPI = await gameSessionShow(this.gameSession.id);
      this.currency = gameSessionsShowAPI.data.response.gameSession.game.currency;
      this.$store.commit('setCurrentGame',gameSessionsShowAPI.data.response.gameSession.game);

      //Subscribe Pusher Channel
      this.pusherChannel = this.pusherInstance.subscribe(`game-${this.gameSession.id}`)

       //Bind Events
      this.pusherChannel.bind('game-next-day',this.gameNextDayCallback);
      this.pusherChannel.bind('game-update-game-session',this.gameUpdateSessionCallback);
      this.pusherChannel.bind('game-ended',this.gameEndCallback);   
      

      //Get market data points
      const marketDataPointAPI = await marketDataPoints(this.gameSession.id);
      this.marketDataPoints = marketDataPointAPI.data.response.marketDataPoints;

      //Initialize the charts
      this.initializeCharts();

      //Start Game
      const tutorial = localStorage.getItem('gameTutorial');

      if(tutorial){
        this.startGameAPI();
      }

    } catch (error) {
      eventBus.$emit('loader',false);

      if(error.response.data.userMessage.includes('ongoing game')){
        this.$swal({
          title: 'Oops',
          text: 'You already have an ongoing game. Starting a new game will end your current game. Would you like to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            gameEnd(this.$route.params.gameId).then((res) => {
              window.removeEventListener('beforeunload', this.handlePageLeave);
              location.reload();
            })
          }else{
            window.removeEventListener('beforeunload', this.handlePageLeave);
            this.gameEnded = true;
            this.isForcedQuit = true;
            this.$router.go(-1);
          }
        })
      }else{
        this.$swal({
          icon: 'error',
          title: 'Oops!',
          text: error.response.data.userMessage
        }).then((result) => {
          this.gameEnded = true;
          this.isForcedQuit = true;
          this.$router.go(-1);
        });
      }


      
    }

    eventBus.$emit('loader',false);

  },
  mounted(){
    this.watchResize();
    window.addEventListener('resize',this.watchResize);
  },
  methods:{
     gameNextDayCallback(data){
      console.log(`GAME NEXT DAY: %c${moment().format('MMMM Do YYYY, h:mm:ss a')}`,'background: #222; color: #bada55');
      this.currentGameTimer = null;
      this.$refs.timer.startTimer();
      this.$refs.mobileTimer.startTimer();
    },
    async gameUpdateSessionCallback(response){
    console.log(`GAME UPDATE SESSION: %c${moment().format('MMMM Do YYYY, h:mm:ss a')}`,'background: #222; color: #bada55');
      // console.log('gameUpdateSessionCallback');

      await this.updateGameDetails(response);
      console.log(`DAY: %c${this.gameSession.current_duration}`,'background: #222; color: white');
    },
    gameEndCallback(response){
      this.updateGameDetails(response)
      this.gameEnded = true;
      this.gameStarted = false;
      this.$refs.timer.shutdownTimer();
      this.$refs.mobileTimer.shutdownTimer();
      this.pusherInstance.unsubscribe(`game-${this.$route.params.gameId}`)
      this.pusherChannel.unbind();
      window.removeEventListener('beforeunload',this.handlePageLeave);
        if(!this.isForcedQuit){
          this.$swal({
            icon: response.data.success ? 'success' : 'error',
            title: response.data.title,
            text: response.data.description
          });
        }
    },
    handlePageLeave(){
      const event = window.event;

      // For IE and Firefox prior to version 4
      if (event) {
        event.returnValue = 'Sure?';
      }
      return 'Sure?';
    },
    initializeCharts(){

      const dataInitalization = {
        chart:{
          type: "Candles",
          data: this.formattedJsonData,
          tf: '1D'
        },
        offchart: [
         {
          name: "RSI",
          type: "RSI",
          data: []
         },
         {
          name: "MACD",
          type: "MACD",
          data: [],
          settings: {
              "histColors": [
                  "#35a776", "#79e0b3", "#e54150", "#ea969e"
              ]
          }
          }
        ]
      }
      this.chartsDc = new DataCube(dataInitalization)

      setTimeout(() => {
        this.chartWidth = document.querySelector('.charts').clientWidth;
        this.chartHeight = document.querySelector('.charts').clientHeight;

        const firstData = this.formattedJsonData[0][0];

        let lastData;
        if(this.formattedJsonData.length > 151){
          lastData = this.formattedJsonData[150][0];
        }else{
          lastData = this.formattedJsonData[this.formattedJsonData.length - 10][0]
        }

        this.$refs.chart.setRange(firstData,lastData)
        this.$refs.chart.goto(this.marketDataPoints.length)
      }, 100);

    },
    placeOrderCall(type){
      if(parseInt(this.orderQuantity) <= 0 || this.isAPICalling) return;

      const body = {
        type: type,
        quantity: this.orderQuantity
      }
      this.isAPICalling = true;
    
      console.log(`${type}: %c${moment().format('MMMM Do YYYY, h:mm:ss a')}`,'background: #222; color: #bada55');

      placeOrder(this.gameSession.id, body).then((response) => {
        console.log(`PURCHASED: %c${moment().format('MMMM Do YYYY, h:mm:ss a')}`,'background: #222; color: #0DF331');
        this.orderJournalList.unshift(response.data.response.order);
        this.gameSession = response.data.response.gameSession;
        this.isAPICalling = false;
      }).catch(error => {
        console.log(`ERROR: %c${moment().format('MMMM Do YYYY, h:mm:ss a')}`,'background: #222; color: #FF0000');
        this.$swal({
          icon: 'error',
          title: 'Oops!',
          timer: 5000,
          text: error.response.data.userMessage,
          timerProgressBar: true,
        });
        this.isAPICalling = false;
      });
    },
    updateQuantity(quantity){
      this.orderQuantity = quantity;
    },
    toggleTabs(tab){
      switch (tab) {
        case 0:
          
          break;
      
        default:
          break;
      }
    },
    watchResize(){
      window.innerWidth <= 600 ? this.isMobile = true : this.isMobile = false
      this.chartWidth = document.querySelector('.charts').clientWidth;
      this.chartHeight = document.querySelector('.charts').clientHeight;
    },
    returnLatestFormattedDataPoint(latestData){
      return[
        Number(moment(latestData.date).format("x")), 
        Number(latestData.open),
        Number(latestData.high),
        Number(latestData.low),
        Number(latestData.close),
        Number(latestData.volume)
      ]
    },
    startGameAPI(){
      gameSessionStart(this.gameSession.id)
      .then((startGameAPI) => {
        //Game Timer
        this.gameTimer = startGameAPI.data.response.gameSession.timer;
        this.$refs.timer.startTimer();
        this.$refs.mobileTimer.startTimer();
        console.log(`START GAME: %c${moment().format('MMMM Do YYYY, h:mm:ss a')}`,'background: #222; color: #bada55');
        eventBus.$emit('loader',false);
      }).catch((error) => {
        eventBus.$emit('loader',false);
        if(!error.response){
          //NETWORK ERROR
          this.$swal({
            title: 'Please Check Your Internet Connection',
            text:'Please Try Again',
            showCancelButton: true,
            confirmButtonText: `Retry`,
            denyButtonText: `Exit Game`,
          }).then((result) => {
            
            //Recall API is user wants to try
            if (result.isConfirmed) {
              this.startGameAPI()
            } else if (result.isDismissed) {
              //Exit Game
              this.gameEnded = true;
              this.isForcedQuit = true;
              this.$router.go(-1);
            }
          })
        }else{
          //Server Error
          this.$swal({
            icon: 'error',
            title: 'Oops!',
            text: error.response.data.userMessage
          }).then((result) => {
            this.gameEnded = true;
            this.isForcedQuit = true;
            this.$router.go(-1);
          });
        }
      })
    },
    updateGameDetails(response){
      if(response.data.gameSession.current_duration == this.gameSession.current_duration);

      try {
        this.isAPICalling = true;
        this.gameSession = response.data.gameSession


        //Set Margin Call Status
        this.isMarginCall = response.data.isMarginCall

        //Update Graph
        const latestDataPoint = response.data.latestMarketDataPoint

        this.marketDataPoints.push(latestDataPoint);

        this.chartsDc.update({candle: this.returnLatestFormattedDataPoint(latestDataPoint)});
        setTimeout(() => {
          this.$refs.chart.goto(this.marketDataPoints.length)
        }, 500);

        if(response.data?.order){
          if(this.orderJournalList.length > 0){
            console.log(response.data.order);
            this.orderJournalList.unshift(response.data.order);
          }else{
            console.log(response.data.order);
            this.orderJournalList.push(response.data.order);
          }
        }
        this.isAPICalling = false
      }catch(error){
        this.isAPICalling = false
      }
    },
    handleGamePopOut(data){


      // Margin Call
      if(data == 'openMarginCall'){
        this.dummyMarginCall = true;
      }else if(data == 'closeMarginCall'){
        Swal.close();
        this.dummyMarginCall = false;
        this.dummyInsufficientFunds = true;
      }
    },
    showEndedHandler(){
      Swal.close();
      this.dummyGameEnded = true;
       
      this.dummyInsufficientFunds = false;
    },
    startGameHandler(){
      this.dummyGameEnded = false;
      Swal.close()
      this.startGameAPI();
      localStorage.setItem('gameTutorial',true)
    }
  },
  computed:{
    formattedJsonData(){
      return this.marketDataPoints.map(function(data){
        return [
          Number(moment(data.date).format("x")), 
          Number(data.open),
          Number(data.high),
          Number(data.low),
          Number(data.close),
          Number(data.volume)
        ]
      })
    },
    computedCurrentDayPrice(){
      const lastItem = this.marketDataPoints.slice(-1)[0];
      if(lastItem == undefined)
        return ''

      return lastItem.close;

    },
  },
  watch:{
    computedCurrentDayPrice(newData){
      this.currentDayPrice = newData;
    },
    isMarginCall(newData){
      const vm = this;
      if(newData == true){
        Swal.fire({
          icon: 'warning',
          title: 'Margin Call!',
          timer: 5000,
          timerProgressBar: true,
          willClose: () => {
            vm.isMarginCall = false;
          }
        });
      }
    },
    dummyMarginCall(newData){
      if(newData == true){
        Swal.fire({
          icon: 'warning',
          title: 'Margin Call!',
          showConfirmButton: false,
          customClass: {
            popup: "margin-call-container"
          }
        });
      }
    },
    dummyInsufficientFunds(newData){
      if(newData == true){
        Swal.fire({
          icon: 'error',
          title: 'Insufficient funds!',
          showConfirmButton: false,
          customClass: {
            popup: "insuf-funds-container"
          }
        });
      }
    },
    dummyGameEnded(newData){
      if(newData == true){
        Swal.fire({
          icon: 'success',
          title: 'Your current game has ended',
          text: 'You may receive the game on this screen or in your profile',
          showConfirmButton: false,
          customClass: {
            popup: "game-ended-container"
          }
        });
      }
    }
  },
  async beforeDestroy(){
    if(!this.gameEnded){
      await gameSessionEnd(this.gameSession.id);
    }
    window.removeEventListener('beforeunload', this.handlePageLeave);
    window.removeEventListener('resize', this.watchResize);
    this.pusherInstance.unsubscribe(`game-${this.gameSession.id}`)
    this.pusherChannel.unbind()
  }
}
</script>

<style lang="scss" scoped>

/deep/ .trading-vue-botbar{
  height: 1px;
  display: block;
  border-top: 1px solid rgb(51, 51, 51);
}

/deep/ #trading-vue-js-botbar-canvas{
  display: none;
}

.note{
  font-size: 12px;
  opacity: 0.5;
  margin-top: auto;

  @media screen and (max-width:600px){
    display: none;
  }

  &.mobile{
    display: none;
    margin: 0 0 10px 20px;

    @media screen and (max-width:600px){
      display: initial;
    }

  }
}

.first-row{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;


  .current-day-information{
    font-weight: 700;
    font-size: 18px;

    @media screen and (max-width:1280px){ 
      font-size: 13px;
    }

    p{
      display: inline-block;
      width: 60px;

      @media screen and (max-width:1280px){ 
        width: 40px;
      }
    }

    span{
      color: $red;
      margin-left: 10px;
    }
  }
}

.first-row-mobile{
  align-items: center;
  background: white;
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 10;

  .tabs{
    display: flex;
    width: 100%;
    

    .tab-item{
      flex: 1 1;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 10px;
      display: grid;
      place-items: center;
      background: $grayDark;
      color: white;
      cursor: pointer;

      &.active{
        background: $red;
      }
    }
  }

  .day-info{
    display: flex;
    align-items: center;
    margin: 15px 0;
    margin-left: 15px;

    .current-day-information{
      font-weight: 700;
      font-size: 18px;
      margin-left: 20px;

      @media screen and (max-width:1280px){ 
        font-size: 13px;
      }

      p{
        display: inline-block;
        width: 60px;

        @media screen and (max-width:1280px){ 
          width: 40px;
        }
      }

      span{
        color: $red;
        margin-left: 10px;
      }
    }
  }
}

.quantity-row{
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;

  .quantity-title{
    font-weight: 700;
    font-size: 18px;
    margin-right: 20px;
  }

  .quantity-input{
    padding: 10px;
    border: 1px solid var(--gray414241);
  }
}

.row-title{
  padding: 6px;
  background: var(--gray414241);
  border-radius: 10px;
  display: block;
  color: white;

  @media screen and (max-width:1280px){
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;
  }
}


.game-grid{
  display: flex;
  width: calc(100vw - 280px);
  position: relative;


  @media screen and (max-width:768px){
    width: calc(100vw - 60px);
  }

  @media screen and (max-width:600px){
    width: calc(100vw - 30px);
    flex-direction: column;
  }

  @media screen and (max-width:450px){
    width: 100vw;
  }
}

.left-col{
  flex-grow: 1;
  width: 55%;
  display: flex;
  flex-direction: column;  

  @media screen and (max-width:600px){
     width: 100%
  }

  .order-journal{
    margin-bottom: 10px;
  }
}

.order-journal-mobile{
  margin: 0 1rem 1rem;

  h3{
    font-size: 15px;
  }
}

.charts{
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 0;
}

#chartContainer{
  width: 100%;
  height: 100%;
}


.legend{
  position: absolute;
	left: 12px;
	top: 12px;
	z-index: 1;
	font-size: 15px;
  color: black;
	line-height: 18px;
	font-weight: 300;
}

.game-details{
  // width: 100%;
  max-width: 500px;
  padding-left: 15px;

  @media screen and (max-width:600px){
    padding-left: 0;
    margin: 1rem;
  }
}

.progress {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}


.actions-tab{
  display: flex;
  flex-wrap: wrap;

  .buy-action{
    background-color: var(--green009F00);
    margin-right: 10px;
  }

  .sell-action{
    background-color: #C60000;
  }
}

.action{
  padding: 10px 30px;
  color: white;
  cursor: pointer;
  border-radius: 2px;
  font-size: 17px;
  border: none;
  flex: 0 0 100px;

  @media screen and (max-width:1280px){
    flex: 1 1 100px;
    font-size: 13px;
  }
}

button:disabled,
button[disabled]{
  opacity: 0.5;
  cursor: not-allowed;
}


/deep/ .table {
  border: none;

  .table-options-row{

    & > *{
      padding: 0rem;
    }


    .table-title{
      border-radius: 2px;
      padding: 2px;
      background: none;
      
      h3{
        color: var(--gray414241);
      }
    }

    .table-description{
      background-color: white;
    }
  }
}

/deep/ .table-body-wrapper{
  max-height: 100px;

  @media screen and (max-width:768px) {
    max-height: 80px;

  }
}


.spacerTop20{
  margin-top: 20px
}

.spacerTop10{
  margin-top: 10px
}

/deep/ .legend{
  position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	font-size: 15px;
  color: black;
	line-height: 18px;
	font-weight: 300;
}

#countdown{
  /deep/ #wrapper{
    text-align: center;
  }
}
</style>